<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="2">
            <h4>CUATRECASAS</h4>
          </v-col>
          <v-col cols="12" sm="12" md="8" class="text-center">
            <otherstatistics :data="statistics_data1_km" :isPageCat="'KM'" />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="text-right d-flex align-center"
              >
                <v-btn color="primary" dark>
                  {{ $t("newitem") }}
                </v-btn>
                <v-btn color="warning" dark class="ml-2">
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <Kilometers ref="Kilometers" :isteam="isteam" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Kilometers from "./kilometers";
import Otherexpenses from "./otherexpenses";
import otherstatistics from "./otherstatistics";

export default {
  name: "approval",
  props: ["isteam"],
  components: {
    Kilometers,
    Otherexpenses,
    otherstatistics
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isSPV", "isGPV", "user"]),
    ...mapGetters("expenses", [
      "statistics_data1_km",
      "statistics_data1_other_expenses"
    ])
  },
  methods: {
    init() {}
  },
  mounted() {
    this.$store.dispatch("auth/getGlobalState");
    this.init();
  }
};
</script>

<style></style>
