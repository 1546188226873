<template>
  <div class="status_wrapper">
    <v-row>
      <v-col cols="12" sm="3" md="3" class="m-auto">
        <b-card
          bg-variant="dark"
          v-bind:header="`FACTURAS`"
          class="text-center"
        >
          <b-card-text>2</b-card-text>
        </b-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "otherstatistics",
  props: ["data", "isPageCat"],
  data() {
    return {
      statistics_data: {
        current_pending_approval: 0,
        current_incidence: 0,
        rest_pending_approval: 0,
        rest_incidence: 0
      }
    };
  },
  watch: {
    async data(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal) {
        this.statistics_data = newVal;
      }
      return newVal;
    }
  },
  computed: {},
  methods: {
    setdata(data) {
      this.statistics_data = data;
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};
</script>

<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
